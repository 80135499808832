<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons.document">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Documents</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this document?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template>
						<!--						<v-col cols="12">-->
						<!--							<ti-document-upload v-model="document" slug></ti-document-upload>-->
						<!--						</v-col>-->
						<v-col cols="12" lg="6">
							<v-text-field label="File name" v-model="document.filename"></v-text-field>
						</v-col>
						<v-col cols="12" lg="5">
							<v-file-input
								v-model="document.document"
								:label="theLabel"
								:value="document.document"
								:prepend-icon="$icons.document"
							></v-file-input>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field label="Slug" v-model="document.slug"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>

			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {call, sync} from "vuex-pathify";
import Models from "../../models";
import vue from "vue";

export default {

	name: "DocumentsEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		theLabel() {
			return this.document.file || 'Document'
		},
		document: sync('documents/document')
	},
	data: () => ({
		search: '',
		valid: false,
		validations: {
			content: validationRules('Content', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.getDocument(this.id)
			} else {
				this.document = Models.Document()
			}
		},
		getDocument: call('documents/getDocument'),
		save() {
			let form = new FormData
			form.append('document[id]', this.document.id)
			form.append('document[filename]', this.document.filename)
			form.append('document[slug]', this.document.slug)
			if (this.document) {
				form.append('document[document]', this.document.document)
			}
			if (this.document.id) {
				console.log('updating');
				this.$api.documents.update(this.document.id, form)
					.then(response => {
						this.$route.meta.disableWarn = true;
						this.$router.push({name: 'Documents'})
					})
					.catch(error => {
						this.$toast.add(error.response.statusText, 'error')
					})
			} else {
				console.log('creating');
				this.$api.documents.create(form)
					.then(response => {
						this.$route.meta.disableWarn = true;
						this.$router.push({name: 'Documents'})
					})
					.catch(error => {
						this.$toast.add(error.response.statusText, 'error')
					})
			}
		},
		destroy() {
			this.$api.documents.delete(this.id, this.document)
				.then(response => {
					this.$toast.add('Document deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Documents'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>